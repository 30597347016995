import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Menu = props => (
  <nav id="menu">
    <div className="inner">
      <ul className="links">
        <li>
          <Link onClick={props.onToggleMenu} to="/">
            Home
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/oud">
            Oud
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/lessons">
            Lessons
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/about">
            About
          </Link>
        </li>
      </ul>
    </div>
    <a
      role="button"
      tabIndex="0"
      className="close"
      onClick={props.onToggleMenu}
      onKeyDown={e => {
        if (e.keyCode === 13) props.onToggleMenu();
      }}
    >
      Close
    </a>
  </nav>
);

Menu.propTypes = {
  onToggleMenu: PropTypes.func
};

export default Menu;
