const path = require("path");

module.exports = {
  flags: {},
  siteMetadata: {
    title: "Özgür Mutlu",
    author: "Özgür Mutlu",
    description: "Personal website of Özgür Mutlu.",
    contactEmail: "letslearnoud@gmail.com",
    instagram: "lets_learn_oud"
  },
  plugins: [
    "gatsby-plugin-react-helmet",
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: "gatsby-theme-forty",
        short_name: "theme",
        start_url: "/",
        background_color: "#663399",
        theme_color: "#663399",
        display: "minimal-ui",
        icon: "src/assets/images/website-icon.png" // This path is relative to the root of the site.
      }
    },
    {
      resolve: `gatsby-plugin-page-creator`,
      options: {
        path: path.join(__dirname, `src`, `pages`)
      }
    },
    "gatsby-plugin-sass",
    "gatsby-plugin-offline"
  ]
};
