import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Header = props => (
  <header id="header" className="alt">
    <Link to="/" className="logo">
      <strong>Oud for All</strong> <span>by Ozgur Mutlu</span>
    </Link>
    <nav>
      <a
        role="button"
        tabIndex="0"
        className="menu-link"
        onClick={props.onToggleMenu}
        onKeyDown={e => {
          if (e.keyCode === 13) props.onToggleMenu();
        }}
      >
        Menu
      </a>
    </nav>
  </header>
);

Header.propTypes = {
  onToggleMenu: PropTypes.func
};

export default Header;
